<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>
                  <router-link :to="{ name: 'staff' }">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  Staff
                </h4>
              </div>
              <div class="col-6 text-right" v-if="checkIsAccessible('user', 'create')">
                <router-link :to="{ name: 'staff-create' }" class="btn btn-primary ml-2">
                  Add Staff
                </router-link>
                <!--                <button @click="import_excel = !import_excel" class="btn btn-primary ml-2">-->
                <!--                 -->
                <!--                  Import Student-->
                <!--                </button>-->
              </div>

              <v-row class="text-center">
                <v-col cols="12" class="text-center">
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-lg-3">
                        <v-text-field
                          class="form-control"
                          v-model="search.first_name"
                          label="First Name"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                      <div class="col-lg-3">
                        <v-text-field
                          class="form-control"
                          v-model="search.last_name"
                          label="Last Name"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                      <div class="col-lg-3">
                        <v-text-field
                          class="form-control"
                          v-model="search.phone"
                          label="Phone"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                      <div class="col-lg-3">
                        <v-text-field
                          class="form-control"
                          v-model="search.email"
                          label="Email"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>

                      <!--                      <div class="col-lg-3">-->
                      <!--                        <v-select-->
                      <!--                            outlined-->
                      <!--                            dense-->
                      <!--                            class="form-control"-->
                      <!--                            v-model="search.class_id"-->
                      <!--                            :items="academic_classes"-->
                      <!--                            label="Class Name"-->
                      <!--                            item-value="id"-->
                      <!--                            item-text="title"-->
                      <!--                        ></v-select>-->
                      <!--                      </div>-->
                    </div>
                    <div class="form-group row">
                      <div class="col-lg-6">
                        <button
                          class="form-control form-control-lg btn btn-danger font-size-lg"
                          @click.prevent="resetFilter"
                        >
                          Reset
                        </button>
                      </div>
                      <div class="col-lg-6">
                        <button
                          v-on:click.stop="getUsers"
                          class="form-control form-control-lg btn btn-success font-size-lg"
                          name="phone"
                          ref="phone"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="col-12">
                <table class="table table-stripe">
                  <thead>
                    <th>Photo</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) of users" :key="index">
                      <td>
                        <div class="symbol-label">
                          <img
                            v-if="user.image_path && user.image_path.thumb"
                            :src="user.image_path.thumb"
                            class="cursor-pointer"
                            alt=""
                            style="height: 30px"
                          />
                          <span v-else class="symbol symbol-35 symbol-light-success">
                            <span
                              class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                            >
                              {{ user.full_name.charAt(0) }}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td>
                        <router-link
                          :to="{ name: 'students-summary', params: { id: user.id } }"
                        >
                          {{ user.full_name }}
                        </router-link>
                      </td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.phone }}</td>
                      <div v-if="user.type.length > 0">
                        <span
                          class="badge badge-secondary m-1"
                          v-for="(t, i) of user.type"
                          :key="i"
                          >{{ t.toUpperCase() }}</span
                        >
                      </div>
                      <div v-else>
                        <span>-</span>
                      </div>
                      <td>
                        <span
                          :class="
                            user.is_active ? 'badge badge-success' : 'badge badge-danger'
                          "
                          >{{ user.is_active ? "Active" : "Inactive" }}</span
                        >
                      </td>
                      <td>
                        <div class="kt-widget__toolbar">
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown"
                            no-caret
                            right
                            no-flip
                            text="Actions"
                          >
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                                <!--begin::Svg Icon-->
                                <i class="flaticon-more-1"></i>
                                <!--end::Svg Icon-->
                                <!--              </span>-->
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a href="#" class="navi-link" @click="editUser(user.id)">
                                  <span class="navi-icon">
                                    <i class="flaticon-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  href="#"
                                  class="navi-link"
                                  @click="manageUserRole(user.id)"
                                >
                                  <span class="navi-icon">
                                    <i class="fas fa-cog mr-2"></i>
                                  </span>
                                  <span class="navi-text">Manage Role</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12 text-right">
                  <b-pagination
                    @input="getUsers"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";

const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();
export default {
  name: "users",
  components: {},
  data() {
    return {
      import_excel: false,
      isLoading: false,
      excel_file: null,
      search: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        type: "staff",
        limit: 25,
      },
      users: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      page: null,
      total: null,
      perPage: null,
    };
  },
  mounted() {
    this.getUsers();
    this.getLevels();
    this.getAcademicYears();
  },
  methods: {
    resetFilter() {
      this.search = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        academic_year_id: "",
        level_id: "",
        program_id: "",
        grade_id: "",
        class_id: "",
        type: "student",
        limit: 25,
      };
    },
    getUsers() {
      userService.paginate(this.search, this.page).then((response) => {
        this.users = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        // alert(this.length)
      });
    },
    editUser(id) {
      return this.$router.push({ name: "staff-update", params: { id: id } });
    },
    getLevels() {
      levelService.all().then((response) => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then((response) => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then((response) => {
        this.academicYears = response.data;
      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then((response) => {
        this.gradesLevels = response.data;
      });
    },
    getAcademicClasses() {
      academicClassService
        .getByProgramLevel(
          this.search.academic_year_id,
          this.search.program_id,
          this.search.grade_id
        )
        .then((response) => {
          this.academic_classes = response.data;
        });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            userService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");

                this.getSlider();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    manageUserRole(id) {
      this.$router.push({ name: "user-role", params: { id, id } });
    },
    importStudent() {
      this.isLoading = true;
      let fd = new FormData();
      fd.append("file", this.excel_file);
      userService.importUser(fd).then((res) => {
        this.isLoading = false;
        this.excel_file = null;
        this.import_excel = false;
        this.$snotify.success("Information imported");
        this.getUsers();
      });
    },
  },
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
